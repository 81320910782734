import React, {useEffect} from "react";
import {
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
} from "/@/gql/";
import { Button, Table } from "@mantine/core";
import { getApiUrl } from '../../config';
import {io} from "socket.io-client";
import { UseAuth } from "../../contexts";

const __TAKE__ = 20;

const ChatList = () => {

  const { user } = UseAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const skip = searchParams.get("page")? (parseInt(searchParams.get("page"), 10) * 20) : 0;
  const pageNumber = parseInt(searchParams.get("page"), 10) ?? 0

  const { data, refetch } = useQuery(
    GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
    {
      variables: {
        args: {
          skip: skip,
          take: __TAKE__
        }
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const socket = io(getApiUrl(),
    {
      query: {
        type: 'notification'
      },
    });
    
    socket.connect()

    socket.on('notification', () => {
      refetch()
    })
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('notification')
    }
  }, [])

  return (
    <>
    
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Order reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Last message</Table.Th>
          <Table.Th>Sender</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data && (data?.getLastUnreadOfEachOrder?.notification ?? []).map((chat) => {
          return (
            <Table.Tr key={chat.order.id} style={chat.read === false && chat.userGroup.id !== user.userGroupId ? {fontWeight: 'bold'} : {}}>
              <Table.Td>{chat.order.orderReference}</Table.Td>
              <Table.Td>{chat.order.status}</Table.Td>
              <Table.Td>{chat.message.length > 50 ? chat.message.slice(0,50) + '...' : chat.message}</Table.Td>
              <Table.Td>{chat.userGroup.name}</Table.Td>
              <Table.Td>
                {
                  new Date(chat.createdAt).toDateString() === new Date().toDateString() ?
                    new Date(chat.createdAt).toLocaleTimeString().split(':').splice(0,2).join(':')
                    : new Date(chat.createdAt).toLocaleDateString()
                }
              </Table.Td>
              <Table.Td>
                <Button onClick={() => navigate(`/orders/${chat.order.id}`)}>
                  View order
                </Button>
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
    {data && ((data?.getLastUnreadOfEachOrder?.notification ?? []).length > 0) &&
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div>
            {pageNumber > 0 &&
              <Button
                onClick={() => {
                  navigate(`/chats?page=${pageNumber - 1}`)
                }}
              >
                Previous page
              </Button>
            }
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {pageNumber + 1}
          </div>
          <div>
            {(data?.getLastUnreadOfEachOrder?.notification ?? []).length % __TAKE__ === 0
             && data?.getLastUnreadOfEachOrder?.count !== skip + __TAKE__
             &&
              <Button
                onClick={() => {
                  navigate(`/chats?page=${pageNumber + 1}`)
                }}
              >
                Next page
              </Button>
            }
          </div>
        </div>
      }
    </>
  );
};

export default ChatList;
